import axios from 'axios';

const JOB_LOCATION_SEARCH_URL = '/api/search/locationAutocomplete/';
let controller = new AbortController();

export async function getJobLocations(query) {
    let response = null;
    try {
        response = await axios.post(
            JOB_LOCATION_SEARCH_URL,
            {
                query,
            },
            {
                signal: controller.signal,
            }
        );
    } catch (error) {
        if (error.message !== 'canceled') {
            console.error(error);
        }
    }

    return response?.data?.data;
}

function abortJobLocationRequest() {
    controller.abort();
    controller = new AbortController();
}

export { abortJobLocationRequest };
