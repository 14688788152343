import React, { useState, useEffect, useRef } from 'react';
import { KEY_CODES } from '../../../js/constants.js';

function SearchInput({
    type,
    label,
    value,
    autocomplete,
    onChange,
    onAutocompleteSelect,
}) {
    const inputRef = useRef();
    const dropDownRef = useRef();
    const [isAutocomplete, setAutocomplete] = useState(false);
    const [isArrowVisible, setIsArrowVisible] = useState(true);

    function onSearchInputChange(evt) {
        const value = evt.target.value;
        onChange(value);

        if (!value.trim().length) {
            setAutocomplete(false);
        }
    }

    function onAutocompleteItemClick(option) {
        onChange(option);
        setAutocomplete(false);
        onAutocompleteSelect(true);
    }

    function onInputKeyDown(evt) {
        if (evt.keyCode === KEY_CODES.DOWN_ARROW && isAutocomplete) {
            evt.preventDefault();
            onAutocompleteSelect(true);
            initOnKeyDropdownListeners();
        } else if (
            evt.keyCode === KEY_CODES.TAB ||
            evt.keyCode === KEY_CODES.SHIFT
        ) {
            onAutocompleteSelect(true);
        } else {
            onAutocompleteSelect(false);
        }
    }

    function initOnKeyDropdownListeners() {
        const firstDropdownElement =
            dropDownRef.current.querySelector('button');
        focusDropdownElement(firstDropdownElement);
        dropDownRef.current.addEventListener('keydown', onKeyDropdown);
    }

    function onKeyDropdown(evt) {
        evt.stopPropagation();
        evt.preventDefault();

        if (evt.keyCode === KEY_CODES.ENTER || evt.keyCode === KEY_CODES.ESC) {
            inputRef.current.querySelector('input').focus();
            setAutocomplete(false);
            return;
        }

        let nextElement = null;
        const currentElementInFocus =
            dropDownRef.current.querySelector('button:focus').parentNode;

        if (evt.keyCode === KEY_CODES.DOWN_ARROW) {
            nextElement = currentElementInFocus.nextSibling;
        }

        if (evt.keyCode === KEY_CODES.UP_ARROW) {
            nextElement = currentElementInFocus.previousSibling;
        }

        if (nextElement) {
            focusDropdownElement(nextElement.querySelector('button'));
        }
    }

    function focusDropdownElement(element) {
        element.focus();
        onChange(element.textContent);
    }

    function handleListScroll(event) {
        if (
            event.target.scrollTop >=
            event.target.scrollHeight - event.target.offsetHeight - 3
        ) {
            setIsArrowVisible(false);
        } else {
            setIsArrowVisible(true);
        }
    }

    useEffect(() => {
        if (!autocomplete?.length) {
            setAutocomplete(false);
        } else {
            setAutocomplete(true);
        }
    }, [autocomplete]);

    useEffect(() => {
        const onClick = (e) =>
            inputRef.current.contains(e.target) || setAutocomplete(false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }, []);

    return (
        <div
            className={`search-bar__input search-bar__input--${type}`}
            ref={inputRef}
        >
            <input
                type="search"
                name="search"
                id={type}
                placeholder={label}
                onChange={onSearchInputChange}
                onKeyDown={onInputKeyDown}
                value={value}
            />
            <label htmlFor={type}>{label}</label>
            {isAutocomplete ? (
                <div
                    className="search-bar__list-wrapper"
                    onScroll={handleListScroll}
                >
                    <ul
                        className={`search-bar__autocomplete search-bar__autocomplete--${type}`}
                        ref={dropDownRef}
                    >
                        {autocomplete.map((option, index) => (
                            <li
                                className="search-bar__autocomplete-option"
                                key={`autocomplete-${index}`}
                            >
                                <button
                                    type="button"
                                    onClick={() =>
                                        onAutocompleteItemClick(option)
                                    }
                                >
                                    {option}
                                </button>
                            </li>
                        ))}
                    </ul>
                    {isArrowVisible && (
                        <div className="search-bar__dropdown-arrow-wrapper">
                            <svg className="icon">
                                <use xlinkHref="#arrow-down"></use>
                            </svg>
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );
}

export default SearchInput;
