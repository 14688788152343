import React from 'react';
import ReactDOM from 'react-dom/client';
import SearchBar from './components/SearchBar/SearchBar.jsx';

const rootElement = document.getElementById('search-bar');
if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <SearchBar />
        </React.StrictMode>
    );
}
