import React, { useEffect, useRef, useState } from 'react';
import { KEY_CODES } from '../../../js/constants';

const SearchDropdown = ({ type, value, onChange, options }) => {
    const dropdownWrapperRef = useRef();
    const dropDownRef = useRef();

    const [isOpened, setIsOpened] = useState(false);
    const [isArrowVisible, setIsArrowVisible] = useState(options.length > 10);

    const toggleDropdown = () => {
        setIsOpened((prev) => !prev);
    };

    const handleButtonClick = () => {
        toggleDropdown();
    };

    const handleOptionClick = (option) => {
        setIsOpened(false);
        onChange(option);
    };

    function onButtonKeyDown(evt) {
        if (evt.keyCode === KEY_CODES.DOWN_ARROW) {
            evt.preventDefault();
            initOnKeyDropdownListeners();
        }
    }

    function initOnKeyDropdownListeners() {
        const firstDropdownElement =
            dropDownRef.current.querySelector('button');
        focusDropdownElement(firstDropdownElement);
        dropDownRef.current.addEventListener('keydown', onKeyDropdown);
    }

    function onKeyDropdown(evt) {
        evt.stopPropagation();
        evt.preventDefault();

        if (evt.keyCode === KEY_CODES.ENTER || evt.keyCode === KEY_CODES.ESC) {
            dropdownWrapperRef.current.querySelector('button').focus();
            return;
        }

        let nextElement = null;
        const currentElementInFocus =
            dropDownRef.current.querySelector('button:focus').parentNode;

        if (evt.keyCode === KEY_CODES.DOWN_ARROW) {
            nextElement = currentElementInFocus.nextSibling;
        }

        if (evt.keyCode === KEY_CODES.UP_ARROW) {
            nextElement = currentElementInFocus.previousSibling;
        }

        if (nextElement) {
            focusDropdownElement(nextElement.querySelector('button'));
        }
    }

    function focusDropdownElement(element) {
        element.focus();
        onChange(element.textContent);
    }

    function handleListScroll(event) {
        if (
            event.target.scrollTop >=
            event.target.scrollHeight - event.target.offsetHeight
        ) {
            setIsArrowVisible(false);
        } else {
            setIsArrowVisible(true);
        }
    }

    useEffect(() => {
        const onClick = (e) =>
            !dropdownWrapperRef.current.contains(e.target) &&
            setIsOpened(false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }, []);

    return (
        <div
            className={`search-bar__dropdown-wrapper search-bar__dropdown-wrapper--${type}`}
            ref={dropdownWrapperRef}
        >
            <button
                className="search-bar__dropdown-button"
                type="button"
                onClick={handleButtonClick}
                onKeyDown={onButtonKeyDown}
            >
                {value}
            </button>
            {isOpened && (
                <div
                    className="search-bar__list-wrapper"
                    onScroll={handleListScroll}
                >
                    <ul
                        role="listbox"
                        className="search-bar__dropdown"
                        ref={dropDownRef}
                    >
                        {options.map((option) => (
                            <li
                                key="specialism"
                                role="option"
                                aria-selected={value === option ? true : false}
                                className="search-bar__dropdown-option"
                            >
                                <button
                                    type="button"
                                    onClick={() => handleOptionClick(option)}
                                >
                                    {option}
                                </button>
                            </li>
                        ))}
                    </ul>
                    {isArrowVisible && (
                        <div className="search-bar__dropdown-arrow-wrapper">
                            <svg className="icon">
                                <use xlinkHref="#arrow-down"></use>
                            </svg>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchDropdown;
