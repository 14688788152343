import React, { useEffect, useRef, useState } from 'react';
import SearchInput from './SearchInput.jsx';
import {
    getJobTitles,
    abortJobTitleRequest,
} from '../../api/search/jobTitle.js';
import {
    getJobLocations,
    abortJobLocationRequest,
} from '../../api/search/jobLocation.js';
import {
    getJobSpecialisms,
    abortJobSpecialismRequest,
} from '../../api/search/jobSpecialism.js';
import debounce from '../../../js/helpers/debounce.js';
import generateSearchUrl from '../../helpers/generateSearchUrl.js';
import SearchDropdown from './SearchDropdown.jsx';
import eventBus from '../../../js/helpers/event-bus.js';

const MIN_SEARCH_STRING_LENGTH = 3;

function SearchBar() {
    const specialismDefaultValue = 'Specialism';
    const errorMessage = 'Please select a Specialism';

    const searchBarRef = useRef();
    const [titleValue, setTitleValue] = useState('');
    const [locationValue, setLocationValue] = useState('');
    const [specialismValue, setSpecialismValue] = useState(
        specialismDefaultValue
    );
    const [searchUrl, _setSearchUrl] = useState('');
    const searchUrlRef = useRef(searchUrl);

    const [jobAutocomplete, setJobAutocomplete] = useState([]);
    const [locationAutocomplete, setLocationAutocomplete] = useState([]);

    const [specialisms, setSpecialisms] = useState([]);

    const [isJobFilled, setJobFilled] = useState(false);
    const [isLocationFilled, setLocationFilled] = useState(false);

    const [isValid, setIsValid] = useState(true);

    const setSearchUrl = () => {
        const url = generateSearchUrl({
            jobTitle: titleValue,
            location: locationValue,
            specialism: specialismValue,
        });
        searchUrlRef.current = url;
        _setSearchUrl(url);
    };

    async function onSearchJobChange(jobTitle) {
        const jobTitles = await getJobTitles(jobTitle);
        setJobAutocomplete(jobTitles);
    }

    async function onSearchLocationChange(location) {
        const locations = await getJobLocations(location);
        setLocationAutocomplete(locations);
    }

    function parseUrlString(string) {
        return string
            .replaceAll(/[^a-zA-Z0-9&]/g, ' ')
            .replaceAll('+or+', '/')
            .split(' ')
            .map((str) => {
                return str.charAt(0).toUpperCase() + str.slice(1);
            })
            .join(' ');
    }

    function setCurrentSpecialismFromUrl(specialisms) {
        const url = new URL(window.location.href);
        const currentPaths = url.pathname.split('/');
        let specialismURL = currentPaths[2];

        if (specialismURL && currentPaths[1] === 'jobs') {
            const currentSpecialism = parseUrlString(specialismURL);
            if (!specialism) return;
            const specialism = specialisms.find(
                (specialism) =>
                    specialism.toLowerCase() === currentSpecialism.toLowerCase()
            );
            setSpecialismValue(specialism ?? specialismDefaultValue);
        }
    }

    const debouncedSearchJobApiCall = useRef(debounce(onSearchJobChange));
    const debouncedSearchLocationApiCall = useRef(
        debounce(onSearchLocationChange)
    );
    useEffect(() => {
        setSearchUrl();
    }, [titleValue, locationValue, specialismValue]);

    useEffect(() => {
        if (isJobFilled) {
            return;
        }

        if (titleValue.trim().length >= MIN_SEARCH_STRING_LENGTH) {
            debouncedSearchJobApiCall.current(titleValue);
        }
    }, [titleValue, isJobFilled]);

    useEffect(() => {
        if (isLocationFilled) {
            return;
        }

        if (locationValue.trim().length >= MIN_SEARCH_STRING_LENGTH) {
            debouncedSearchLocationApiCall.current(locationValue);
        }
    }, [locationValue, isLocationFilled]);

    useEffect(() => {
        const getJobSpecialismsList = async () => {
            const specialisms = await getJobSpecialisms('');
            setSpecialisms(specialisms);
            setCurrentSpecialismFromUrl(specialisms);
        };

        eventBus.addListener('filter:change', () => {
            setSpecialismValue(specialismDefaultValue);
        });

        eventBus.addListener('filters:clearAll', () => {
            setSpecialismValue(specialismDefaultValue);
        });
        getJobSpecialismsList();
    }, []);

    const handleDropdownChange = (option) => {
        setIsValid(true);
        setSpecialismValue(option);
    };

    function onSearchSubmit(evt) {
        evt.preventDefault();

        if (specialismValue === specialismDefaultValue) {
            setIsValid(false);
            return;
        }

        abortJobLocationRequest();
        abortJobTitleRequest();
        abortJobSpecialismRequest();
        window.location.href = searchUrlRef.current;
    }

    return (
        <form onSubmit={onSearchSubmit} autoComplete="off">
            <div className="search-bar__wrapper" ref={searchBarRef}>
                <SearchDropdown
                    type={'specialism'}
                    value={specialismValue}
                    onChange={handleDropdownChange}
                    options={specialisms}
                />
                <SearchInput
                    type={'jobTitle'}
                    label={'Search by job title...'}
                    value={titleValue}
                    autocomplete={jobAutocomplete}
                    onChange={setTitleValue}
                    onAutocompleteSelect={setJobFilled}
                />
                <SearchInput
                    type={'location'}
                    label={'Location'}
                    value={locationValue}
                    autocomplete={locationAutocomplete}
                    onChange={setLocationValue}
                    onAutocompleteSelect={setLocationFilled}
                />

                <button
                    className="search-bar__submit-button button"
                    type="submit"
                    onClick={onSearchSubmit}
                >
                    Search jobs
                </button>
                {!isValid && (
                    <div className="search-bar__error">{errorMessage}</div>
                )}
            </div>
        </form>
    );
}

export default SearchBar;
