const KEY_CODES = Object.freeze({
    DOWN_ARROW: 40,
    UP_ARROW: 38,
    ENTER: 13,
    ESC: 27,
    TAB: 9,
    SHIFT: 16,
});

export { KEY_CODES };
