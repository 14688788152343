const BASE_URL = '/jobs/';

function generateSearchUrl({
    jobTitle = null,
    location = null,
    specialism = null,
}) {
    let url = BASE_URL;
    if (specialism) {
        url += `${encodeURIComponent(specialism)
            .replaceAll(' ', '-')
            .replaceAll('%20', '-')
            .replaceAll('%2F', '+or+')}/`;
    }
    if (location) {
        url += `${encodeURIComponent(location)
            .replaceAll(' ', '-')
            .replaceAll('%20', '-')}/`;
    }
    if (jobTitle) {
        url += `?jobTitle=${encodeURIComponent(jobTitle)}`;
    }
    return url;
}

export default generateSearchUrl;
