const DEBOUNCE_INTERVAL_IN_MS = 300;

export default function debounce(cb) {
    let lastTimeout = null;

    return function () {
        const parameters = arguments;
        if (lastTimeout) {
            clearTimeout(lastTimeout);
        }
        lastTimeout = setTimeout(function () {
            cb.apply(null, parameters);
        }, DEBOUNCE_INTERVAL_IN_MS);
    };
}
